<template>
   <main>
    <Navbar />
    <div class="main" :class="{'vh-100': info.financeiro.apuracao == 0 && !isLoading}">
        <div class="container">
            <template v-if="pedidos_de_saques">
                <div class="alert alert-warning alert-dismissible fade show" role="alert">
                    <strong>Olá, {{ user_name }}!</strong> Você tem (<strong>{{ pedidos_de_saques }}</strong>) {{ pedidos_de_saques == 1 ? 'pedido de saque pendente.' : 'pedidos de saques pendentes' }}. <a class="btn btn-sm btn-secondary" href="/dashboard/transactions">Verificar</a>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </template>
            <template v-if="info.financeiro.apuracao == 0 && !isLoading">
                <div class="d-flex vh-100 justify-content-center align-items-center">
                    <div class="text-center p-3 rounded-pill" :class="platform == 'desktop' ? 'w-50' : 'w-100'">
                        <div class="d-flex flex-column">
                            <div class="display-3 text-white">
                                <i class="fas fa-exclamation-square"></i>
                            </div>
                            <div class="text-white mb-2">Sem informações</div>
                        </div><!-- /flex-column-->
                    </div><!-- /w-100 -->
                </div><!-- /d-flex -->
            </template>
            <template v-else>
                <div class="chart bg-dark rounded mb-3 p-3">
                    <template v-if="isLoading">
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="spinner-border spinner-border-sm text-warning mr-2" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <canvas id="graphic" width="400" height="100"></canvas>
                    </template>
                </div>
                <div class="row mb-3">
                    <div :class="platform == 'desktop' ? 'col pr-0' : 'col-12'">
                        <div class="card bg-dark text-white p-3">
                            <template v-if="isLoading">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="spinner-border spinner-border-sm text-warning mr-2" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h5 class="mb-0">
                                            <span class="counter" :data-count="info.financeiro.apuracao">0,00</span>
                                        </h5>
                                    </div><!-- /col -->
                                    <div class="col-auto">
                                        <i class="fas fa-piggy-bank"></i>
                                    </div><!-- /col-3 -->
                                </div><!-- /row -->
                                <h6 class="text-warning font-weight-normal">Apuração</h6>
                            </template>
                        </div><!-- /card -->
                    </div><!-- /col -->
                    <div :class="platform == 'desktop' ? 'col' : 'col-12 mt-3'">
                        <div class="card bg-dark text-white p-3">
                            <template v-if="isLoading">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="spinner-border spinner-border-sm text-warning mr-2" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h5 class="mb-0">
                                            <span class="counter" :data-count="info.financeiro.premiacao">0,00</span>
                                        </h5>
                                    </div><!-- /col -->
                                    <div class="col-auto">
                                        <i class="fas fa-award"></i>
                                    </div><!-- /col-3 -->
                                </div><!-- /row -->
                                <h6 class="text-warning font-weight-normal">Premiações</h6>
                            </template>
                        </div><!-- /card -->
                    </div><!-- /col -->
                    <div class="w-100 p-2"></div>
                    <div :class="platform == 'desktop' ? 'col pr-0' : 'col-12'">
                        <div class="card bg-dark text-white p-3">
                            <template v-if="isLoading">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="spinner-border spinner-border-sm text-warning mr-2" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h5 class="mb-0">
                                            <span class="counter" :data-count="info.financeiro.possiveis_pagamentos">0,00</span>
                                        </h5>
                                    </div><!-- /col -->
                                    <div class="col-auto">
                                        <i class="fas fa-hands-usd"></i>
                                    </div><!-- /col-3 -->
                                </div><!-- /row -->
                                <h6 class="text-warning font-weight-normal">Possíveis pagamentos</h6>
                            </template>
                        </div><!-- /card -->
                    </div><!-- /col -->
                    <div :class="platform == 'desktop' ? 'col' : 'col-12 mt-3'">
                        <div class="card bg-dark text-white p-3">
                            <template v-if="isLoading">
                                <div class="d-flex justify-content-center align-items-center">
                                    <div class="spinner-border spinner-border-sm text-warning mr-2" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h5 class="mb-0">
                                            <span class="counter" :data-count="info.financeiro.saldo">0,00</span>
                                        </h5>
                                    </div><!-- /col -->
                                    <div class="col-auto">
                                        <i class="fas fa-hand-holding-usd"></i>
                                    </div><!-- /col-3 -->
                                </div><!-- /row -->
                                <h6 class="text-warning font-weight-normal">Saldo</h6>
                            </template>
                        </div><!-- /card -->
                    </div><!-- /col -->
                </div><!-- /row -->
                <div class="row mb-3">
                    <div :class="platform == 'desktop' && pipe == 3 ? 'col pr-0' : 'col-12'">
                        <div class="card bg-dark text-white p-3">
                            <div class="row border-bottom mb-3">
                                <div class="col">
                                    <h5 class="mb-3">Apostas</h5>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-receipt"></i>
                                </div>
                            </div>
                            <div class="row">
                                <template v-if="isLoading">
                                    <div class="d-flex w-100 justify-content-center align-items-center">
                                        <div class="spinner-border spinner-border-sm text-warning mr-2" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-12 pb-3 border-bottom">
                                        <div class="d-flex justify-content-between">
                                            <span class="text-warning">Total</span>
                                            <span class="counter" data-number="true" :data-count="info.apostas.total">0</span>
                                        </div><!-- /d-flex -->
                                    </div><!-- /col -->
                                    <div class="col-12 mt-3 pb-3 border-bottom">
                                        <div class="d-flex justify-content-between">
                                            <span class="text-warning">Aberta</span>
                                            <span class="counter" data-number="true" :data-count="info.apostas.aguardando">0</span>
                                        </div><!-- /d-flex -->
                                    </div><!-- /col -->
                                    <div class="col-12 mt-3 pb-3 border-bottom">
                                        <div class="d-flex justify-content-between">
                                            <span class="text-warning">Perdeu</span>
                                            <span class="counter" data-number="true" :data-count="info.apostas.perdedores">0</span>
                                        </div><!-- /d-flex -->
                                    </div><!-- /col -->
                                    <div class="col-12 mt-3 pb-3 border-bottom">
                                        <div class="d-flex justify-content-between">
                                            <span class="text-warning">Ganhou</span>
                                            <span class="counter" data-number="true" :data-count="info.apostas.ganhadores">0</span>
                                        </div><!-- /d-flex -->
                                    </div><!-- /col -->
                                    <div class="col-12 pt-3">
                                        <div class="d-flex justify-content-between">
                                            <span class="text-warning">Encerradas</span>
                                            <span class="counter" data-number="true" :data-count="info.apostas.encerradas">0</span>
                                        </div><!-- /d-flex -->
                                    </div><!-- /col -->
                                </template>
                            </div><!-- /row -->
                        </div><!-- /card -->
                    </div><!-- /col -->
                    <div :class="platform == 'desktop' ? 'col' : 'col-12 mt-3'" v-if="pipe == 3">
                        <div class="card bg-dark text-white p-3">
                            <div class="row border-bottom mb-3">
                                <div class="col">
                                    <h5 class="mb-3">Eventos</h5>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-fire"></i>
                                </div>
                            </div>
                            <div class="row">
                                <template v-if="isLoading">
                                    <div class="d-flex w-100 justify-content-center align-items-center">
                                        <div class="spinner-border spinner-border-sm text-warning mr-2" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col-12 pb-3 border-bottom">
                                        <div class="d-flex justify-content-between">
                                            <span class="text-warning">Restantes</span>
                                            <span class="counter" data-number="true" :data-count="info.eventos.restantes">0</span>
                                        </div><!-- /d-flex -->
                                    </div><!-- /col -->
                                    <div class="col-12 mt-3 pb-3 border-bottom">
                                        <div class="d-flex justify-content-between">
                                            <span class="text-warning">Em andamento</span>
                                            <span class="counter" data-number="true" :data-count="info.eventos.andamento">0</span>
                                        </div><!-- /d-flex -->
                                    </div><!-- /col -->
                                    <div class="col-12 mt-3 pb-3 border-bottom">
                                        <div class="d-flex justify-content-between">
                                            <span class="text-warning">Encerrados</span>
                                            <span class="counter" data-number="true" :data-count="info.eventos.encerrados">0</span>
                                        </div><!-- /d-flex -->
                                    </div><!-- /col -->
                                    <div class="col-12 mt-3 pb-3 border-bottom">
                                        <div class="d-flex justify-content-between">
                                            <span class="text-warning">Cancelados</span>
                                            <span class="counter" data-number="true" :data-count="info.eventos.cancelados">0</span>
                                        </div><!-- /d-flex -->
                                    </div><!-- /col -->
                                    <div class="col-12 pt-3">
                                        <div class="d-flex justify-content-between">
                                            <span class="text-warning">Adiados</span>
                                            <span class="counter" data-number="true" :data-count="info.eventos.adiados">0</span>
                                        </div><!-- /d-flex -->
                                    </div><!-- /col -->
                                </template>
                            </div><!-- /row -->
                        </div><!-- /card -->
                    </div><!-- /col -->
                </div><!-- /row -->
                <div class="row mb-3">
                    <div :class="platform == 'desktop' ? 'col' : 'col-12'">
                        <div class="card bg-dark text-white pt-3 pl-3 pr-3">
                            <div class="row border-bottom">
                                <div class="col">
                                    <h5 class="mb-3">Top vendedores</h5>
                                </div>
                                <div class="col-auto" role="button" @click="controlVendedorLimite">
                                    <template v-if="limite.vendedores == 10">
                                        <i class="fas fa-plus-square"></i>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-minus-square"></i>
                                    </template>
                                </div>
                            </div>
                            <div class="row">
                                <template v-if="isLoading">
                                    <div class="d-flex w-100 justify-content-center align-items-center pt-3 pb-3">
                                        <div class="spinner-border spinner-border-sm text-warning mr-2" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="row-scrolling pl-3 pr-3 pb-3">
                                        <div class="row" v-for="(item, index) in salesmanOrganizes(info.estatistica.vendedores)" :key="index">
                                            <div class="col border-bottom pb-3 mb-3 col-header" :class="{'border-top pt-3 mt-3': index > 0, 'pt-3': index == 0}">
                                                <div class="row align-items-center">
                                                    <div class="col-auto pr-0">
                                                        <img class="rounded" :src="`${gravatar_api}&background=${(item.apurado - item.premiacao) < 0 ? 'dc3545': '28a745'}&name=${item.nome}`" width="35" height="35" :alt="item.nome" onerror="this.src='/images/avatar.png'">
                                                    </div>
                                                    <div class="col text-warning text-truncate">
                                                        <div class="d-flex flex-column">
                                                            <div class="text-truncate">{{item.nome}}</div>
                                                            <div class="text-muted text-truncate">{{item.atividade}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col text-truncate text-right">
                                                        <div class="d-flex flex-column">
                                                            <div>
                                                                <span class="counter" data-number="true" :data-count="item.apostas">0</span>
                                                            </div>
                                                            <div class="text-muted text-truncate">{{getGeolocation(item.geolocation)}}</div>
                                                        </div>
                                                    </div>
                                                </div><!-- /row -->
                                            </div><!-- /col -->
                                            <div class="w-100"></div>
                                            <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 pb-3 border-bottom'">
                                                <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                    <span class="text-warning">Apurou</span>
                                                    <span class="counter" :data-count="item.apurado">0</span>
                                                </div><!-- /d-flex -->
                                            </div><!-- /col -->
                                            <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 mt-3 pb-3 border-bottom'">
                                                <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                    <span class="text-warning">Premiações</span>
                                                    <span class="counter" :data-count="item.premiacao">0</span>
                                                </div><!-- /d-flex -->
                                            </div><!-- /col -->
                                            <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 mt-3'">
                                                <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                    <span class="text-warning">Saldo</span>
                                                    <span class="counter" :data-count="item.apurado - item.premiacao">0</span>
                                                </div><!-- /d-flex -->
                                            </div><!-- /col -->
                                        </div><!-- /row -->
                                    </div><!-- /row-scrolling -->
                                </template>
                            </div><!-- /row -->
                        </div><!-- /card -->
                    </div><!-- /col -->
                </div><!-- /row -->
                <div class="row mb-3">
                    <div :class="platform == 'desktop' ? 'col' : 'col-12'">
                        <div class="card bg-dark text-white pt-3 pl-3 pr-3">
                            <div class="row border-bottom">
                                <div class="col">
                                    <h5 class="mb-3">Apostas por filtros</h5>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-filter"></i>
                                </div>
                            </div>
                            <div class="row">
                                <template v-if="isLoading || loads.apostas_altas || loads.apostas_de_risco">
                                    <div class="d-flex w-100 justify-content-center align-items-center pt-3 pb-3">
                                        <div class="spinner-border spinner-border-sm text-warning mr-2" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <ul class="nav nav-pills nav-justified w-100 p-3" :class="{'flex-column': platform == 'mobile'}" id="pills-tab" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="pills-1-tab" data-toggle="pill" href="#pills-1" role="tab" aria-controls="pills-1" aria-selected="true">Valores altos</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="pills-2-tab" data-toggle="pill" href="#pills-2" role="tab" aria-controls="pills-2" aria-selected="false">Risco</a>
                                        </li>
                                        <li class="nav-item" v-if="pipe == 3">
                                            <a class="nav-link" id="pills-3-tab" data-toggle="pill" href="#pills-3" role="tab" aria-controls="pills-3" aria-selected="false">Possíveis ganhadores</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content w-100" id="pills-tabContent">
                                        <div class="tab-pane fade show active" id="pills-1" role="tabpanel" aria-labelledby="pills-1-tab">
                                            <template v-if="info.apostas.valores_altos.length == 0">
                                                <div class="d-flex w-100 justify-content-center align-items-center flex-column pt-2 pb-3">
                                                    <div class="display-4 text-white">
                                                        <i class="fas fa-exclamation-square"></i>
                                                    </div>
                                                    <div class="text-white">Nenhuma informação disponível</div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="row-scrolling pl-3 pr-3 pb-3">
                                                    <div class="row" v-for="(item, index) in valoresAltosOrder" :key="index" @click="viewBet(item.id)" role="button">
                                                        <div class="col border-bottom pb-3 mb-3 col-header" :class="{'border-top pt-3 mt-3': index > 0, 'pt-3': index == 0}">
                                                            <div class="row align-items-center">
                                                                <div class="col-auto pr-0">
                                                                    <img class="rounded" :src="`${gravatar_api}&background=5f9ea0&name=${item.apostador}`" width="35" height="35" :alt="item.apostador" onerror="this.src='/images/avatar.png'">
                                                                </div>
                                                                <div class="col text-warning text-truncate">
                                                                    <div class="d-flex flex-column">
                                                                        <div class="text-truncate">{{item.apostador}}</div>
                                                                        <div class="text-muted text-truncate">{{item.atividade}}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col text-truncate text-right">
                                                                    <div class="d-flex flex-column">
                                                                        <div>
                                                                            <i class="fas fa-eye"></i>
                                                                        </div>
                                                                        <div class="text-muted text-truncate">{{getGeolocation(item.geolocation)}}</div>
                                                                    </div>
                                                                </div>
                                                            </div><!-- /row -->
                                                        </div><!-- /col -->
                                                        <div class="w-100"></div>
                                                        <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 pb-3 border-bottom'">
                                                            <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                                <span class="text-warning">Código</span>
                                                                <span class="text-uppercase">{{item.codigo}}</span>
                                                            </div><!-- /d-flex -->
                                                        </div><!-- /col -->
                                                        <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 mt-3 pb-3 border-bottom'">
                                                            <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                                <span class="text-warning">Valor</span>
                                                                <span class="counter" :data-count="item.valor">0</span>
                                                            </div><!-- /d-flex -->
                                                        </div><!-- /col -->
                                                        <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 mt-3'">
                                                            <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                                <span class="text-warning">Prêmio</span>
                                                                <span class="counter" :data-count="item.premio">0</span>
                                                            </div><!-- /d-flex -->
                                                        </div><!-- /col -->
                                                    </div><!-- /row -->
                                                </div><!-- /row-scrolling -->                                        
                                            </template>
                                        </div><!-- /pills-1 -->
                                        <div class="tab-pane fade" id="pills-2" role="tabpanel" aria-labelledby="pills-2-tab">
                                            <template v-if="info.apostas.risco.length == 0">
                                                <div class="d-flex w-100 justify-content-center align-items-center flex-column pt-2 pb-3">
                                                    <div class="display-4 text-white">
                                                        <i class="fas fa-exclamation-square"></i>
                                                    </div>
                                                    <div class="text-white">Nenhuma informação disponível</div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="row-scrolling pl-3 pr-3 pb-3">
                                                    <div class="row" v-for="(item, index) in apostasDeRiscoOrder" :key="index" @click="viewBet(item.id)" role="button">
                                                        <div class="col border-bottom pb-3 mb-3 col-header" :class="{'border-top pt-3 mt-3': index > 0, 'pt-3': index == 0}">
                                                            <div class="row align-items-center">
                                                                <div class="col-auto pr-0">
                                                                    <img class="rounded" :src="`${gravatar_api}&background=8a2be2&name=${item.apostador}`" width="35" height="35" :alt="item.apostador" onerror="this.src='/images/avatar.png'">
                                                                </div>
                                                                <div class="col text-warning text-truncate">
                                                                    <div class="d-flex flex-column">
                                                                        <div class="text-truncate">{{item.apostador}}</div>
                                                                        <div class="text-muted text-truncate">{{item.atividade}}</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col text-truncate text-right">
                                                                    <div class="d-flex flex-column">
                                                                        <div>
                                                                            <i class="fas fa-eye"></i>
                                                                        </div>
                                                                        <div class="text-muted text-truncate">{{getGeolocation(item.geolocation)}}</div>
                                                                    </div>
                                                                </div>
                                                            </div><!-- /row -->
                                                        </div><!-- /col -->
                                                        <div class="w-100"></div>
                                                        <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 pb-3 border-bottom'">
                                                            <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                                <span class="text-warning">Código</span>
                                                                <span class="text-uppercase">{{item.codigo}}</span>
                                                            </div><!-- /d-flex -->
                                                        </div><!-- /col -->
                                                        <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 mt-3 pb-3 border-bottom'">
                                                            <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                                <span class="text-warning">Valor</span>
                                                                <span class="counter" :data-count="item.valor">0</span>
                                                            </div><!-- /d-flex -->
                                                        </div><!-- /col -->
                                                        <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 mt-3'">
                                                            <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                                <span class="text-warning">Prêmio</span>
                                                                <span class="counter" :data-count="item.premio">0</span>
                                                            </div><!-- /d-flex -->
                                                        </div><!-- /col -->
                                                    </div><!-- /row -->
                                                </div><!-- /row-scrolling -->                                        
                                            </template>
                                        </div><!-- /pills-2 -->
                                        <div class="tab-pane fade" id="pills-3" role="tabpanel" aria-labelledby="pills-3-tab" v-if="pipe == 3">
                                            <template v-if="info.apostas.possiveis_premiacoes.length">
                                                <div class="row-scrolling pl-3 pr-3 pb-3 pt-3">
                                                    <div class="row mb-3">
                                                        <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 pb-3 border-bottom'">
                                                            <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                                <span class="text-warning">Total</span>
                                                                <span class="counter-2" data-number="true" :data-count="total_apostas">0</span>
                                                            </div><!-- /d-flex -->
                                                        </div><!-- /col -->
                                                        <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 mt-3'">
                                                            <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                                <span class="text-warning">Premiações</span>
                                                                <span class="counter-2" :data-count="total_premios">0</span>
                                                            </div><!-- /d-flex -->
                                                        </div><!-- /col -->
                                                    </div><!-- /row -->
                                                    <div class="row" v-for="(item, index) in possiveisPremiacoesOrder" :key="index" @click="viewBet(item.id)" role="button">
                                                        <div class="col border-bottom pb-3 mb-3 col-header" :class="{'border-top pt-3 mt-3': index > 0, 'pt-3': index == 0}">
                                                            <div class="row align-items-center">
                                                                <div class="col-auto pr-0">
                                                                    <img class="rounded" :src="`${gravatar_api}&background=a52a2a&name=${item.apostador}`" width="35" height="35" :alt="item.apostador" onerror="this.src='/images/avatar.png'">
                                                                </div>
                                                                <div class="col text-warning text-truncate">
                                                                    <div class="d-flex flex-column">
                                                                        <div class="text-truncate">{{item.apostador}}</div>
                                                                        <div class="text-success text-truncate">Acertos</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col text-truncate text-right">
                                                                    <div class="d-flex flex-column">
                                                                        <div>
                                                                            <i class="fas fa-eye"></i>
                                                                        </div>
                                                                        <div class="text-success text-truncate">{{item.total_acertos}} de {{item.total_palpites}}</div>
                                                                    </div>
                                                                </div>
                                                            </div><!-- /row -->
                                                        </div><!-- /col -->
                                                        <div class="w-100"></div>
                                                        <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 pb-3 border-bottom'">
                                                            <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                                <span class="text-warning">Código</span>
                                                                <span class="text-uppercase">{{item.codigo}}</span>
                                                            </div><!-- /d-flex -->
                                                        </div><!-- /col -->
                                                        <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 mt-3 pb-3 border-bottom'">
                                                            <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                                <span class="text-warning">Valor</span>
                                                                <span class="counter-2" :data-count="item.valor">0</span>
                                                            </div><!-- /d-flex -->
                                                        </div><!-- /col -->
                                                        <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 mt-3'">
                                                            <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                                <span class="text-warning">Prêmio</span>
                                                                <span class="counter-2" :data-count="item.premio">0</span>
                                                            </div><!-- /d-flex -->
                                                        </div><!-- /col -->
                                                    </div><!-- /row -->
                                                </div><!-- /row-scrolling -->                                        
                                            </template>
                                            <template v-else>
                                                <template v-if="display_show == 0 || loads.possiveis_ganhadores">
                                                    <div class="d-flex w-100 justify-content-center align-items-center pt-3 pb-3">
                                                        <div class="spinner-border spinner-border-sm text-warning mr-2" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else-if="display_show == 1 && !loads.possiveis_ganhadores">
                                                    <div class="d-flex w-100 justify-content-center align-items-center pt-3 pb-3">
                                                        <button class="btn btn-warning" @click="possibleAwards">
                                                            <i class="fas fa-sync mr-2"></i>Carregar as informações
                                                        </button>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="d-flex w-100 justify-content-center align-items-center flex-column pt-2 pb-3">
                                                        <div class="display-4 text-white">
                                                            <i class="fas fa-exclamation-square"></i>
                                                        </div>
                                                        <div class="text-white">Nenhuma informação disponível</div>
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </div><!-- /row -->
                        </div><!-- /card -->
                    </div><!-- /col -->
                </div><!-- /row -->
                <div class="row mb-3" v-if="pipe == 3">
                    <div :class="platform == 'desktop' ? 'col' : 'col-12'">
                        <div class="card bg-dark text-white pt-3 pl-3 pr-3">
                            <div class="row border-bottom">
                                <div class="col">
                                    <h5 class="mb-3">Eventos mais apostados</h5>
                                </div>
                                <div class="col-auto" role="button" @click="controlEventosLimite">
                                    <template v-if="limite.eventos_mais_apostados == 10">
                                        <i class="fas fa-plus-square"></i>
                                    </template>
                                    <template v-else>
                                        <i class="fas fa-minus-square"></i>
                                    </template>
                                </div>
                            </div>
                            <div class="row">
                                <template v-if="eventsOrganizes(info.estatistica.eventos).length">
                                    <div class="row-scrolling pl-3 pr-3 pr-3 pb-3">
                                        <div class="row" v-for="(item, index) in eventsOrganizes(info.estatistica.eventos)" :key="index" @click="viewStatistics(item.id, item.time_casa, item.time_fora)" role="button">
                                            <div class="col border-bottom pb-3 mb-3 col-header" :class="{'border-top pt-3 mt-3': index > 0, 'pt-3': index == 0}">
                                                <div class="row align-items-end">
                                                    <div class="col-auto pr-0">
                                                        <div class="d-flex flex-column align-items-center">
                                                            <img class="mr-2 mb-1 align-middle object-fit" :src="`${image_server}/s/${imageShield(item.time_casa)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" width="20" height="20" border="0">
                                                            <img class="mr-2 align-middle object-fit" :src="`${image_server}/s/${imageShield(item.time_fora)}`" @error="imageError($event, '/images/shield_default.png')" @load="imageCheck" width="20" height="20" border="0">
                                                        </div>
                                                    </div>
                                                    <div class="col pl-0 text-warning text-truncate">
                                                        <div class="d-flex flex-column">
                                                            <div class="text-truncate">{{item.time_casa}}</div>
                                                            <div class="text-truncate">{{item.time_fora}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col text-truncate text-right">
                                                        <div class="d-flex flex-column">
                                                            <div>
                                                                <span class="counter-2" data-number="true" :data-count="item.escalacoes">0</span>
                                                            </div>
                                                            <div class="text-muted text-truncate">{{item.liga}}</div>
                                                        </div>
                                                    </div>
                                                </div><!-- /row -->
                                            </div><!-- /col -->
                                            <div class="w-100"></div>
                                            <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 pb-3 border-bottom'">
                                                <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                    <span class="text-warning">Pré-jogo</span>
                                                    <span class="counter-2" data-number="true" :data-count="item.escalacoes_pre_jogo">0</span>
                                                </div><!-- /d-flex -->
                                            </div><!-- /col -->
                                            <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 mt-3 pb-3 border-bottom'">
                                                <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                    <span class="text-warning">Ao vivo</span>
                                                    <span class="counter-2" data-number="true" :data-count="item.escalacoes_ao_vivo">0</span>
                                                </div><!-- /d-flex -->
                                            </div><!-- /col -->
                                            <div :class="platform == 'desktop' ? 'col border-right' : 'col-12 mt-3'">
                                                <div class="d-flex" :class="platform == 'desktop' ? 'flex-column align-items-center' : 'justify-content-between'">
                                                    <span class="text-warning">Valor apostado</span>
                                                    <span class="counter-2" :data-count="item.agregado">0</span>
                                                </div><!-- /d-flex -->
                                            </div><!-- /col -->
                                        </div><!-- /row -->
                                    </div><!-- /row-scrolling -->
                                </template>
                                <template v-else>
                                    <template v-if="display_show == 0">
                                        <div class="d-flex w-100 justify-content-center align-items-center pt-3 pb-3">
                                            <div class="spinner-border spinner-border-sm text-warning mr-2" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else-if="display_show == 1">
                                        <div class="d-flex w-100 justify-content-center align-items-center pt-3 pb-3">
                                            <button class="btn btn-warning" @click="mostBetEvents">
                                                <i class="fas fa-sync mr-2"></i>Carregar as informações
                                            </button>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="d-flex w-100 justify-content-center align-items-center flex-column pt-2 pb-3">
                                            <div class="display-4 text-white">
                                                <i class="fas fa-exclamation-square"></i>
                                            </div>
                                            <div class="text-white">Nenhuma informação disponível</div>
                                        </div>
                                    </template>
                                </template>
                            </div><!-- /row -->
                        </div><!-- /card -->
                    </div><!-- /col -->
                </div><!-- /row -->
            </template>
        </div><!-- /container -->
    </div><!-- /main -->
    <modal name="view-bet" :width="platform == 'desktop' ? 650 : screen_w" :height="platform == 'desktop' ? 500 : screen_h" :adaptive="true" @before-close="beforeClose" :classes="['modal-dark','modal-new']">
        <template v-if="template && betData">
            <div class="modal-topbar">
                <div class="row align-items-center">
                    <div class="col text-truncate">
                        <i class="fas fa-hashtag align-middle"></i>
                        <span class="text-uppercase text-truncate">
                            {{ betData.codigo }}
                        </span> 
                    </div>
                    <div class="col-auto text-right">
                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <div @click="generateTicketImage" class="mr-3" role="button" title="Baixar imagem">
                                <i class="fas fa-download"></i>
                            </div>
                            <div @click="$modal.hide('view-bet')" role="button">
                                <i class="fal fa-times"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /modal-topbar -->
            <div class="modal-view" v-html="template"></div>
        </template>
    </modal><!-- /modal-view -->
    <modal name="view-statistics" :width="platform == 'desktop' ? 650 : screen_w" :height="platform == 'desktop' ? 500 : screen_h" :adaptive="true" @before-close="beforeClose" :classes="['modal-dark','modal-new']">
        <template v-if="template && staticData">
            <div class="modal-topbar">
                <div class="row align-items-center">
                    <div class="col text-truncate">
                        <i class="far fa-analytics align-middle mr-2"></i>
                        <span class="text-uppercase text-truncate">Estatísticas</span>
                    </div>
                    <div class="col-auto text-right">
                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <div @click="$modal.hide('view-statistics')" role="button">
                                <i class="fal fa-times"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /modal-topbar -->
            <div class="modal-view" v-html="template"></div>
        </template>
    </modal><!-- /modal-view -->
   <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main><!-- /root -->
</template>

<script>

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import Vue from 'vue'
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import VModal from 'vue-js-modal'
import moment from 'moment-timezone'
import $ from 'jquery/dist/jquery.min.js'
import Chart from 'chart.js/dist/Chart.min.js'
import * as rasterizeHTML from 'rasterizehtml'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

Vue.use(VModal)

export default {
    data() {
        return {
            info: {
                apostas: {
                    data: [],
                    total: 0,
                    risco: [],
                    aguardando: 0,
                    perdedores: 0,
                    ganhadores: 0,
                    canceladas: 0,
                    encerradas: 0,
                    valores_altos: [],
                    possiveis_premiacoes: []                
                },
                eventos: {
                    adiados: 0,
                    restantes: 0,
                    andamento: 0,
                    encerrados: 0,
                    cancelados: 0
                },
                financeiro: {
                    saldo: 0,
                    apuracao: 0,
                    premiacao: 0,
                    possiveis_pagamentos: 0
                }, 
                estatistica: {
                    eventos: {},
                    vendedores: {}
                }
            },
            limite: {
                vendedores: 10,
                valores_altos: 50,
                apostas_de_risco: 50,
                possiveis_premiacoes: 50,
                eventos_mais_apostados: 10
            },
            loads: {
                principal: false,
                vendedores: false,
                apostas_altas: false,
                apostas_de_risco: false,
                possiveis_ganhadores: false
            },
            errors: {
                principal: false,
                vendedores: false,
                apostas_altas: false,
                apostas_de_risco: false,
                possiveis_ganhadores: false
            },
            pipe: 0,
            bets: [],
            betData: '',
            staticData: '',
            template: '',
            user_name: '',
            display: 0,
            display_show: 0,
            total_premios: 0,
            total_apostas: 0,
            loading: false,
            isLoading: false,
            screen_w: window.screen.width,
            screen_h: window.screen.height,
            future_predictions_events: [],
            future_predictions_indicators: 0,
            gravatar_api: 'https://ui-avatars.com/api/?color=fff',
            isClientSrv: window.location.hostname.includes('valsports') ? 2 : 1,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        viewStatistics(id, team_home, team_away) {
            
            const self = this;

            if (self.isClientSrv == 2 && self.info.estatistica.eventos[id] != undefined) {

                const event = self.info.estatistica.eventos[id];

                if (event instanceof Object) {
                    this.mountviewStatistics(event.estatisticas, event.time_casa, event.time_fora);
                } else {
                    Swal.fire(
                        'Falha',
                        'Não foi possível encontrar as informações dessa partida!',
                        'error'
                    );
                }
            } else {

                self.loading = true;

                api.get(`dashboard/event/statistic/${id}`).then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            self.assemblesViewStatistics(response.data, team_home, team_away);
                        break;
                        case 'nothing_found':
                            Swal.fire(
                                'Falha',
                                'Não foi possível encontrar as informações dessa partida!',
                                'error'
                            );
                        break;
                        default:
                            Swal.fire(
                                'Falha',
                                response.data.message,
                                'error'
                            );
                        break;
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.loading = false;
                });
            }
        },
        mountviewStatistics(statistics, team_home, team_away) {

            let index = 0;
            let html = `
                <div class="row top-sticky bg-warning text-dark pt-2 pb-2 rounded">
                    <div class="col text-center text-truncate">${team_home}</div>
                    <div class="col-auto text-center">
                        <i class="fal fa-times"></i>
                    </div>
                    <div class="col text-center text-truncate">${team_away}</div>
                </div>
            `;

            html += `<div id="accordion">`;

            for (let key in statistics) {
                let item = statistics[key];

                html += `
                    <div class="card rounded-0">
                        <div class="card-header" id="heading-${index}">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <button class="btn btn-link text-left ${index > 0 ? 'collapsed' : ''}" data-toggle="collapse" data-target="#collapse-${index}" aria-expanded="true" aria-controls="collapse-${index}">
                                        <span>${item.mercado}</span>
                                    </button>   
                                </div>
                                <div class="col-4 text-right">
                                    <span class="badge badge-warning">${this.formatValue(item.total)}</span>    
                                </div>    
                            </div>
                        </div>
                        <div id="collapse-${index}" class="collapse ${index == 0 ? 'show' : ''}" aria-labelledby="heading-${index}" data-parent="#accordion">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col text-center text-truncate text-dark font-bold">Apostas</div>
                                    <div class="col text-center text-truncate text-dark font-bold">Apurado</div>
                                    <div class="w-100"></div>
                                    <div class="col text-center text-truncate text-dark">${this.formatValue(item.total)}</div>
                                    <div class="col text-center text-truncate text-dark">${this.formatCoin(item.apurado)}</div>
                                    <div class="w-100"></div>
                                    ${this.Arrlength(item.odds) ? `
                                        <div class="col text-center text-style text-primary">Linha do tempo</div>
                                        <div class="w-100"></div>
                                        <div class="col mt-3">
                                            <div class="scroll-horizontal">
                                                ${item.odds.map((data, i) => {
                                                    return `
                                                    <div class="timeline">
                                                        <div class="timeline-container ${i % 2 === 0 ? 'left' : 'right'}">
                                                            <div class="timeline-content">
                                                                <div class="row">
                                                                    <div class="col text-truncate">
                                                                        <h6 class="text-warning mb-0" data-live="${item.live}">
                                                                            ${item.live ? '<i class="fas fa-signal-stream mr-2" title="Ao vivo"></i>' : '<i class="fas fa-clock mr-2" title="Pré-jogo"></i>'}&nbsp;${data.hora}
                                                                        </h6>
                                                                    </div>
                                                                    <div class="col-auto">
                                                                        <span class="text-warning">R$ ${data.odd}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    `;
                                                }).join('')}
                                            </div>
                                        </div>
                                    ` : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                `;

                index++;
            }

            html += `</div>`;

            this.template = html;
            this.staticData = {
                team_home: team_home,
                team_away: team_away
            };
            this.$modal.show('view-statistics');
        },
        Arrlength(arr) {
            return Array.isArray(arr) ? arr.length : false;
        },
        counter() {

            const self = this;
            
            $(document).ready(function() {

                $('.counter, .counter-2').each(function() {

                    let $this = $(this);
                    let countTo = $this.attr('data-count');

                    $({ countNum: $this.text()}).animate({countNum: countTo}, {
                        duration: 1500,
                        easing: 'linear',
                        step: function() {
                            $this.text(Math.floor(this.countNum));
                        },
                        complete: function() {
                            $this.text($this.attr('data-number') ? self.formatValue(this.countNum) : self.formatCoin(this.countNum));
                        }
                    });
                });
            });
        },
        graphic() {

            const self = this;
            const saldo = parseFloat(self.info.financeiro.saldo).toFixed(2);
            const apuracao = parseFloat(self.info.financeiro.apuracao).toFixed(2);
            const premiacao = parseFloat(self.info.financeiro.premiacao).toFixed(2);

            $(document).ready(function() {

                $('.counter').each(function() {

                    let $this = $(this);
                    let countTo = $this.attr('data-count');

                    $({ countNum: $this.text()}).animate({countNum: countTo}, {
                        duration: 1500,
                        easing: 'linear',
                        step: function() {
                            $this.text(Math.floor(this.countNum));
                        },
                        complete: function() {
                            $this.text($this.attr('data-number') ? self.formatValue(this.countNum) : self.formatCoin(this.countNum));
                        }
                    });
                });

                try {
                    new Chart(document.getElementById('graphic').getContext('2d'), {
                        type: 'line',
                        data: {
                            labels: ['Apuração', 'Premiações', 'Saldo'],
                            datasets: [{
                                label: 'Total',
                                data: [apuracao, premiacao, saldo],
                                backgroundColor: [
                                    'rgba(54, 162, 235, 0.2)',
                                    'rgba(255, 206, 86, 0.2)',
                                    'rgba(153, 102, 255, 0.2)'
                                ],
                                borderColor: [
                                    'rgba(54, 162, 235, 1)',
                                    'rgba(255, 159, 64, 1)',
                                    'rgba(153, 102, 255, 1)'
                                ],
                                borderWidth: 1
                            }]
                        },
                        options: {
                            legend: {
                                display: false
                            },
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }
                    });
                } 
                catch(e) 
                {
                    //
                }
            });
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        formatValue(nStr) {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            return x1 + x2;
        },
        controlVendedorLimite() {
            return this.limite.vendedores == 10 ? this.limite.vendedores = 100 : this.limite.vendedores = 10;
        },
        controlApostasLimite() {
            return this.limite.valores_altos == 10 ? this.limite.valores_altos = 100 : this.limite.valores_altos = 10;
        },
        controlApostasDeRiscoLimite() {
            return this.limite.apostas_de_risco == 10 ? this.limite.apostas_de_risco = 100 : this.limite.apostas_de_risco = 10;
        },
        controlEventosLimite() {
            return this.limite.eventos_mais_apostados == 10 ? this.limite.eventos_mais_apostados = 100 : this.limite.eventos_mais_apostados = 10;
        },
        controlPossiveisPremiacoes() {
            return this.limite.possiveis_premiacoes == 10 ? this.limite.possiveis_premiacoes = 10000 : this.limite.possiveis_premiacoes = 10;
        },
        salesmanOrganizes(obj) {

            const arr = [];

            for (let key in obj) {
                arr.push(obj[key]);
            }
            return arr.sort((a, b) => {
                return b.apostas - a.apostas;
            }).slice(0, this.limite.vendedores); 
        },
        eventsOrganizes(obj) {

            const arr = [];

            for (let key in obj) {
                arr.push(obj[key]);
            }

            return arr.sort((a, b) => {
                return b.escalacoes - a.escalacoes;
            }).slice(0, this.limite.eventos_mais_apostados);
        },
        viewBet(id) {

            const self = this;

            self.loading = true;

            api.get(`view-bet/${id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.mountView(id, response.data.hunches);
                    break;
                    default:
                        Swal.fire(
                            'Falha',
                            response.data.message,
                            'error'
                        );
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        mountView(id, hunches) {
            
            try {
                let html = ``;

                const self = this;
                const bets = self.isClientSrv == 2 ? self.bets : self.info.apostas.data;
                const bet = bets.find((item) => item.id == id);
                
                if (bet instanceof Object) {

                    let anteior_value = 0;
                    let hunches_order = self.orderByScheduleByBet(hunches);

                    for (let key in hunches_order) {
                        let palpite = hunches_order[key];

                        if (anteior_value != palpite.league) {
                            html += `
                            <div class="league">
                                <div>${palpite.league}</div>
                            </div>
                            `;
                        }

                        html += `
                        <div class="event">
                            <div class="item">
                                <div class="item-col">${palpite.team_home}</div>
                                <div class="item-col">${self.dateTicket(palpite.schedule)}</div>
                            </div>
                            <div class="item">
                                <div class="item-col">${palpite.team_away}</div>
                                <div class="item-col">R$&nbsp;${palpite.odd}</div>
                            </div>
                            <div class="item">
                                <div class="item-col-3 font-bold">Modalidade</div>
                                <div class="item-col-3 font-bold">Situação</div>
                                <div class="item-col-3 font-bold">Ao vivo</div>
                            </div>
                            <div class="item">
                                <div class="item-col-3">${self.findModality(palpite.sport)}</div>
                                <div class="item-col-3 ${palpite.situation == 1 ? 'hit' : palpite.situation == 2 ? 'lose' : 'default'}">${self.findSituation(palpite.situation)}</div>
                                <div class="item-col-3">${palpite.live ? 'Sim' : 'Não'}</div>
                            </div>
                            <div class="item">
                                <div class="item-market font-bold">${self.findMarketSituation(palpite)}</div>
                            </div>
                        </div>
                        `;

                        anteior_value = palpite.league;
                    }

                    let template = `
                     <div class="ticket-virtual">
                        <div class="header">
                            <div class="item">
                                <div class="logo">
                                    <img src="${self.logo}" border="0" alt="logo" />
                                </div>
                            </div>
                            <div class="item text-right">
                                <div class="font-bold">Código</div>
                                <div>${bet.codigo}</div>
                            </div>
                        </div>
                        <div class="wrap">
                            <h3 class="text-center ${bet.situacao == 1 ? 'd-none' : 'd-block text-underline'}">
                                ${bet.situacao == 2 ? 'Aposta premiada' : bet.situacao == 3 ? 'Aposta perdida' : bet.situacao == 4 ? 'Aposta cancelada' : 'Sem informações'}    
                            </h3>
                            <div class="wrap-item">
                                <div>Vendedor</div>
                                <div>${bet.vendedor}</div>
                            </div>
                            <div class="wrap-item">
                                <div>Apostador</div>
                                <div>${bet.apostador}</div>
                            </div>
                            <div class="wrap-item">
                                <div>Valor apostado</div>
                                <div>R$&nbsp;${self.format_coin(bet.valor)}</div>
                            </div>
                            <div class="wrap-item">
                                <div>Possível prêmio</div>
                                <div>R$&nbsp;${self.format_coin(bet.premio)}</div>
                            </div>
                            <div class="wrap-item">
                                <div>Horário da aposta</div>
                                <div>${self.convertDate(bet.data) + '&nbsp;-&nbsp;' + bet.hora}</div>
                            </div>
                            <h5 class="text-center">
                                Qtd. Palpites: ${Object.keys(hunches).length}    
                            </h5>
                            <div class="wrap-content">
                                <div class="wrap-content-item">
                                    ${html}
                                </div>
                            </div>
                        </div>
                     </div>
                    `;

                    self.betData = bet;
                    self.template = template;
                    self.$modal.show('view-bet');
                } 
                else 
                {
                    Swal.fire(
                        'Atenção!',
                        'Aposta não encontrada!',
                        'error'
                    );
                }
            } 
            catch(e) 
            {
                Swal.fire(
                    'Atenção!',
                   'Ocorreu um erro ao exibir a aposta, atualiza a página e tente novamente!',
                    'error'
                );
            }
        },
        generateTicketImage() {
            try {

                if (!this.template) return;

                const self = this;
                const iframe = document.createElement('iframe');
                const {themeStyleColor, themeTextColor, themeForeground} = self.getThemeStyle();

                if (themeStyleColor && themeTextColor && themeForeground) {

                   let page = `
                        <html>
                            <head>
                                <meta charset="utf-8">
                                <style>
                                    @import url(/css/ticket.css);
                                    :root {
                                        --ticket-style-color: ${themeStyleColor};
                                        --ticket-text-color: ${themeTextColor};
                                        --ticket-foreground: ${themeForeground};
                                    }
                                    html, body {
                                        margin: 0;
                                        padding: 0;
                                        width: 100%;
                                        height: auto;
                                        background-color: var(--ticket-style-color);
                                    }
                                    h5 {
                                        margin-top: 0!important;
                                    }
                                </style>
                            </head>
                            <body>
                                ${self.template}
                            </body>
                        </html>
                    `;

                    self.loading = true;

                    iframe.style.width = '600px';
                    iframe.style.height = 'auto';
                    iframe.src = 'about:blank';
                    iframe.style.visibility = 'hidden';  

                    document.body.appendChild(iframe);
                    iframe.contentWindow.document.write(page);

                    setTimeout(() => {

                        let canvas = document.createElement('canvas');
                        let ticket = iframe.contentWindow.document.querySelector('.ticket-virtual');

                        canvas.width = ticket.offsetWidth;
                        canvas.height = ticket.offsetHeight;

                        rasterizeHTML.drawHTML(page, canvas).then(() => {

                            self.loading = false;

                            iframe.remove();

                            let link = document.createElement('a');
                            link.download = (self.betData ? `TICKET-${(self.betData.codigo).toUpperCase()}.jpeg` : `${Math.floor(Math.random() * 1000000)}.jpeg`);
                            link.href = canvas.toDataURL('image/jpeg');
                            link.click();
                        });
                    }, 100);
                } 
                else 
                {
                    Swal.fire(
                        'Atenção!',
                        'Não foi possível gerar a imagem do comprovante!',
                        'error'
                    );
                }
            } 
            catch(e) 
            {
                Swal.fire(
                    'Atenção!',
                    e.message,
                    'error'
                );
            }
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        convertDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY');
        },
        convertHour(date) {
            return moment(date).tz('America/Recife').format('HH:mm');
        },
        paymentReminder(pipe) {

            const self = this;
            const hostname = window.location.hostname;

            if (pipe == 3 && hostname != 'localhost') {

                api.post('dashboard/check-payment', {
                    client_id: 0,
                    url: hostname,
                    action: 'check-payment'
                }).then((response) => {
                    self.paymentStructure(response.data);
                }).finally(() => {
                    window.sessionStorage.setItem('rememberPayment', 1);
                });
            }
        },
        paymentStructure(data) {

            let title = $('title').text();
            let nocache = new Date().getTime();
            let rememberPayment = window.sessionStorage.getItem('rememberPayment');

            if (data.result == 'remember') {
                if (rememberPayment == null) {
                    Swal.fire({
                        title: 'Pagamento em atraso!',
                        html: `<p class="mt-3">Olá, <b>${title}</b> <br><br> Você está com o pagamento em entraso. <br> Seu pagamento foi definido para o dia <b>${data.date}</b> de cada mês. <br><br> Você tem até <b>3 dias úteis</b> para efetuar o pagamento, antes do cancelamento da sua conta.</p>`,
                        type: 'warning'
                    }).then(() => {
                        this.$router.push('/dashboard/payment');
                    });
                }
            } else if (data.result == 'disabled') {
                document.write(`
                    <html>
                        <head>
                            <meta charset="utf-8">
                            <title>Sistema desativado!</title>
                            <style>html, body{margin: 0}</style>
                        </head>
                        <body>
                            <iframe src="https://betsnow.net/system-disabled.html?nocache=${nocache}" width="100%" height="100%" frameborder="0"></iframe>
                        </body>
                    </html>
                `);
            }
        },
        assemblesViewStatistics(response, team_home, team_away) {

            const estatisticas = {};
            const odds_duplicates = {};

            response.bets.forEach((item) => {

                for (let key in item.palpites) {
                    let palpite = item.palpites[key];

                    if (palpite.id == response.id) {

                        if (estatisticas[palpite.id] == undefined) {
                            estatisticas[palpite.id] = {
                                total: 1,
                                odds: [],
                                live: palpite.live,
                                mercado: 'Apurado geral',
                                apurado: parseFloat(item.valor)
                            };
                        } else {
                            const stats = estatisticas[palpite.id];
                            stats.apurado += parseFloat(item.valor);
                            stats.total += 1;
                        }

                        if (estatisticas[palpite.option] == undefined) {
                            if (odds_duplicates[palpite.option + palpite.odd] == undefined) odds_duplicates[palpite.option + palpite.odd] = true;
                            estatisticas[palpite.option] = {
                                total: 1,
                                odds: [{
                                    odd: palpite.odd,
                                    hora: item.hora || '00:00'
                                }],
                                live: palpite.live,
                                mercado: palpite.market,
                                apurado: parseFloat(item.valor)
                            };
                        } else {
                            const stats = estatisticas[palpite.option];
                            if (odds_duplicates[palpite.option + palpite.odd] == undefined) {
                                odds_duplicates[palpite.option + palpite.odd] = true;
                                stats.odds.push({
                                    odd: palpite.odd,
                                    live: palpite.live,
                                    hora: item.hora || '00:00'
                                });
                            }
                            stats.apurado += parseFloat(item.valor);
                            stats.total += 1;
                        }

                    }
                }
            });

            this.mountviewStatistics(estatisticas, team_home, team_away);
        },
        mountAssemblesStatistics(response) {
            if (this.isClientSrv == 1) {
                this.assemblesStatistics(response);
            } else {
                this.assemblesStatisticsSrv(response);
            }
        },
        assemblesStatistics(response) {

            const self = this;
            const horario = moment().tz('America/Recife').format();

            const apostas_partes = self.separar(response.bets, 1000);
            const apostas_partes_len = apostas_partes.length;

            for (let i = 0; i < apostas_partes_len; i++) {
                const apostas_arr = apostas_partes[i];
                const apostas_arr_len = apostas_arr.length;

                for (let k = 0; k < apostas_arr_len; k++) {
                    const item = apostas_arr[k];

                    self.info.apostas.data.push({
                        id: item._id,
                        data: item.data,
                        hora: item.hora,
                        valor: item.valor,
                        premio: item.premio,
                        codigo: item.codigo,
                        vendedor: item.vendedor,
                        situacao: item.situacao,
                        apostador: item.apostador,
                        geolocation: item.geolocation,
                        atividade: moment(item.horario).locale('pt-br').fromNow()
                    });

                    self.info.apostas.total += 1;
                    self.info.financeiro.apuracao += parseFloat(item.valor);

                    if (item.situacao == 1) {

                        if (item.valor >= 50) {
                            self.info.apostas.valores_altos.push({
                                id: item._id,
                                data: item.data,
                                hora: item.hora,
                                valor: item.valor,
                                premio: item.premio,
                                codigo: item.codigo,
                                vendedor: item.vendedor,
                                situacao: item.situacao,
                                apostador: item.apostador,
                                geolocation: item.geolocation,
                                atividade: moment(item.horario).locale('pt-br').fromNow()
                            });
                        } 
                        
                        if ((item.total_palpites == 1 || item.total_palpites == 2) && item.valor >= 50) {
                            self.info.apostas.risco.push({
                                id: item._id,
                                data: item.data,
                                hora: item.hora,
                                valor: item.valor,
                                premio: item.premio,
                                codigo: item.codigo,
                                vendedor: item.vendedor,
                                situacao: item.situacao,
                                apostador: item.apostador,
                                geolocation: item.geolocation,
                                atividade: moment(item.horario).locale('pt-br').fromNow()
                            });
                        }
                    }

                    if (item.encerrada) {
                        self.info.apostas.encerradas += 1;
                    }

                    if (item.situacao == 1) {
                        self.info.apostas.aguardando += 1;
                        self.info.financeiro.possiveis_pagamentos += parseFloat(item.premio);
                    } else if (item.situacao == 2) {
                        self.info.apostas.ganhadores += 1;
                        self.info.financeiro.premiacao += parseFloat(item.premio);
                    } else if (item.situacao == 3) {
                        self.info.apostas.perdedores += 1;
                    } else {
                        self.info.apostas.canceladas += 1;
                    }

                    // Monta a estatistica dos vendedores que mais realizaram apostas
                    self.info.estatistica.vendedores[item.vendedor_id] = self.info.estatistica.vendedores[item.vendedor_id] != undefined ? self.info.estatistica.vendedores[item.vendedor_id] = {
                        id: item.vendedor_id,
                        nome: item.vendedor,
                        geolocation: item.geolocation,
                        atividade: moment(item.horario).locale('pt-br').fromNow(),
                        apostas: self.info.estatistica.vendedores[item.vendedor_id].apostas + 1,
                        apurado: self.info.estatistica.vendedores[item.vendedor_id].apurado += parseFloat(item.valor),
                        premiacao: self.info.estatistica.vendedores[item.vendedor_id].premiacao += self.calcPremium(item.situacao, item.premio)
                    } : self.info.estatistica.vendedores[item.vendedor_id] = {
                        id: item.vendedor_id,
                        nome: item.vendedor,
                        apostas: 1,
                        apurado: parseFloat(item.valor),
                        geolocation: item.geolocation,
                        premiacao: self.calcPremium(item.situacao, item.premio),
                        atividade: moment(item.horario).locale('pt-br').fromNow()
                    };
                }
            }

            self.info.financeiro.saldo = parseFloat(self.info.financeiro.apuracao) - parseFloat(self.info.financeiro.premiacao);

            response.events.forEach((item) => {

                if (item.horario > horario) {
                    self.info.eventos.restantes += 1;
                } else if (item.horario < horario && item.status == 0) {
                    self.info.eventos.andamento += 1;
                } else if (item.status == 1) {
                    self.info.eventos.encerrados += 1;
                } else if (item.status == 3) {
                    self.info.eventos.adiados += 1;
                } else {
                    self.info.eventos.cancelados += 1;
                }
            });
            
            self.graphic();
            self.possibleAwards();
            self.mostBetEvents();
            self.current_credit = parseFloat(response.credit);
        },
        assemblesStatisticsSrv(response) {
            this.info.financeiro.saldo = response.info.financeiro.saldo;
            this.info.financeiro.apuracao = response.info.financeiro.apuracao;
            this.info.financeiro.premiacao = response.info.financeiro.premiacao;
            this.info.financeiro.possiveis_pagamentos = response.info.financeiro.possiveis_pagamentos;
            this.info.apostas.total = response.info.apostas.total;
            this.info.apostas.aguardando = response.info.apostas.aguardando;
            this.info.apostas.perdedores = response.info.apostas.perdedores;
            this.info.apostas.ganhadores = response.info.apostas.ganhadores;
            this.info.apostas.canceladas = response.info.apostas.canceladas;
            this.info.apostas.encerradas = response.info.apostas.encerradas;
            this.info.eventos.adiados = response.info.eventos.adiados;
            this.info.eventos.restantes = response.info.eventos.restantes;
            this.info.eventos.andamento = response.info.eventos.andamento;
            this.info.eventos.encerrados = response.info.eventos.encerrados;
            this.info.eventos.cancelados = response.info.eventos.cancelados;
            this.current_credit = this.formatCoin(response.credit);
            this.graphic();
            this.rankSalesman();
        },
        rankEvents() {

            if (this.pipe != 3) return;

            this.display = 0;

            api.get('dashboard/rank-events').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        this.mostBetEventsDisplaySrv(response.data);
                    break;
                    default: 
                        this.display = 1;
                    break;
                }
            }).catch(() => {
                this.display = 1;
            });
        },
        rankSalesman() {

            this.loads.vendedores = true;
            this.errors.vendedores = false;

            api.get('dashboard/rank-salesman').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        this.mostBetRankSalesmanDisplay(response.data);
                    break;
                    default: 
                        this.errors.vendedores = true;
                    break;
                }
            }).catch(() => {
                this.errors.vendedores = true;
            }).finally(() => {
                this.loads.vendedores = false;
            });
        },
        mostBetRankSalesmanDisplay(response) {
            try {
                for (let key in response.estatisticas) {
                    this.$set(this.info.estatistica.vendedores, key, response.estatisticas[key]);
                }
            } catch(e) {
                this.errors.vendedores = true;
            } finally {
                this.counter();
                this.rankHighStakes();
            }
        },
        rankHighStakes() {

            this.loads.apostas_altas = true;
            this.errors.apostas_altas = false;

            api.get('dashboard/rank-high-stakes').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        this.bets.push(...response.data.estatisticas);
                        this.info.apostas.valores_altos = response.data.estatisticas;
                        this.rankRiskBets();
                    break;
                    default: 
                        this.errors.apostas_altas = true;
                    break;
                }
            }).catch(() => {
                this.errors.apostas_altas = true;
            }).finally(() => {
                this.loads.apostas_altas = false;
            });
        },
        rankRiskBets() {

            this.loads.apostas_de_risco = true;
            this.errors.apostas_de_risco = false;

            api.get('dashboard/rank-risk-bets').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        this.bets.push(...response.data.estatisticas);
                        this.info.apostas.risco = response.data.estatisticas;
                        this.possibleAwards(2);
                    break;
                    default: 
                        this.errors.apostas_de_risco = true;
                    break;
                }
            }).catch(() => {
                this.errors.apostas_de_risco = true;
            }).finally(() => {
                this.loads.apostas_de_risco = false;
            });
        },
        possibleAwards(type = 1) {

            if (this.pipe == 3) {
                
                if (type == 2) this.rankEvents();

                this.display_show = 0;
                this.loads.possiveis_ganhadores = true;
                this.errors.possiveis_ganhadores = false;

                api.get('dashboard/possible-awards').then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            this.bets.push(...response.data.data.bets);
                            this.possibleAwardsShow(response.data.data.bets);
                        break;
                        case 'nothing_found':
                            this.display_show = 2;
                        break;
                        default: 
                            this.display_show = 1;
                        break;
                    }
                }).catch(() => {
                    this.display_show = 1;
                    this.errors.possiveis_ganhadores = true;
                }).finally(() => {
                    this.loads.possiveis_ganhadores = false;
                });             
            }
        },
        possibleAwardsShow(bets) {

            const self = this;

            bets.forEach((item) => {
                self.total_apostas += 1;
                self.total_premios += parseFloat(item.premio);
            });

            self.info.apostas.possiveis_premiacoes = bets;

            self.counter();
        },
        mostBetEvents() {

            if (this.pipe == 3) {
                
                this.display = 0;

                api.get('dashboard/most-bet-events').then((response) => {
                    switch(response.data.result) {
                        case 'success':
                            this.mostBetEventsDisplay(response.data);
                        break;
                        default: 
                            this.display = 1;
                        break;
                    }
                }).catch(() => {
                    this.display = 1;
                });                
            }
        },
        mostBetEventsDisplay(response) {

            const self = this;
            const today = moment().tz('America/Recife').format('YYYY-MM-DD');

            try {

                if (response.data.date == today) {

                    const apostas_partes = self.separar(response.data.bets, 1000);
                    const apostas_partes_len = apostas_partes.length;

                    for (let i = 0; i < apostas_partes_len; i++) {
                        const apostas_arr = apostas_partes[i];
                        const apostas_arr_len = apostas_arr.length;

                        for (let k = 0; k < apostas_arr_len; k++) {
                            const item = apostas_arr[k];

                            // Monta a estatistica dos jogos mais apostados
                            for (let key in item.palpites) {
                                const palpite = item.palpites[key];

                                if (typeof(self.info.estatistica.eventos[palpite.id]) == 'undefined') 
                                {
                                    self.$set(self.info.estatistica.eventos, palpite.id, {
                                        id: item.betbuilder ? key : palpite.id, 
                                        agregado: parseFloat(item.valor),
                                        escalacoes: 1,
                                        escalacoes_pre_jogo: !palpite.live ? 1 : 0,
                                        escalacoes_ao_vivo: palpite.live ? 1 : 0,
                                        liga: palpite.league,
                                        time_casa: palpite.team_home, 
                                        time_fora: palpite.team_away
                                    });
                                } 
                                else 
                                {
                                    const evento = self.info.estatistica.eventos[palpite.id];

                                    if (palpite.live) {
                                        evento.escalacoes_ao_vivo += 1;
                                    } else {
                                        evento.escalacoes_pre_jogo += 1;
                                    }

                                    evento.escalacoes += 1;
                                    evento.agregado += parseFloat(item.valor);
                                }
                            }
                        }
                    }
                }
                else 
                {
                    self.display = 2;
                }
            } catch(e) {
                self.display = 1;
            } finally {
                self.counter();
            }
        },
        mostBetEventsDisplaySrv(response) {
            try {
                for (let key in response.estatisticas) {
                    this.$set(this.info.estatistica.eventos, key, response.estatisticas[key]);
                }
            } catch(e) {
                this.display = 1;
            } finally {
                this.counter();
            }
        },
        calcPremium(status, premium) {
            return status == 2 ? parseFloat(premium) : 0;
        },
        getTheme() {
            try {
                return window.localStorage.getItem('theme');
            } catch(e) {
                return 'theme-default';
            }
        },
        getGeolocation(geolocation) {
            if (geolocation instanceof Object) {
                if (geolocation.hasOwnProperty('estado') && geolocation.hasOwnProperty('cidade')) {
                    if (geolocation.estado && geolocation.cidade) {
                        return `${geolocation.cidade} - ${geolocation.estado}`;
                    } else {
                        return 'Endereço desconhecido';
                    }
                } else {
                    return 'Endereço desconhecido';
                }
            } else {
                return 'Endereço desconhecido';
            }
        },
        separar(base, maximo) {
            let resultado = [];
            for (let i = 0; i < base.length; i = i + maximo) {
                resultado.push(base.slice(i, i + maximo));
            }
            return resultado;
        },
        imageError(event, image) {
            event.target.src = image;
        },
        imageShield(team) {
            try {
                return `${this.removeAccents(team).toLowerCase().trim().replace(/[^a-z-0-9\s]/g, '').replace(/\s/g, '-')}.png`;
            } catch(e) {
                return 'shield.png';
            }
        },
        imageCheck(event) {
            try {
                var w = event.target.naturalWidth;
                var h = event.target.naturalHeight;

                if (w == 1 && h == 1) {
                    event.target.src = 'shield.png';
                }
            } catch(e) {
                event.target.src = 'shield.png';
            }
        },
        futurePredictions() {

            const self = this;

            api.get('dashboard/future-predictions').then((response) => {
                if (response.data.result == 'success') {
                    self.futurePredictionsStructure(response.data.events);
                }
            }).catch(() => {
                self.futurePredictions();
            });
        },
        futurePredictionsStructure(events) {
            const arr = [];
            const self = this;
            for (let key in events) {
                let event = events[key];
                arr.push(...event.events);
            }
            self.future_predictions_events = this.separar(arr, 3);
            // self.future_predictions_indicators = this.future_predictions_events.length;
        },
        withdrawalRequests() {

            const self = this;

            if (self.pipe == 3) {
                api.get('dashboard/transactions/all/all').then((response) => {
                    self.pedidos_de_saques = response.data.total;
                }).catch(() => {
                    self.withdrawalRequests();
                });
            }
        },
        beforeClose() {
            this.betData = '';
            this.template = '';
            this.staticData = '';
        },
        shareBet(code, valor = 0, premio = 0, apostador = '') {
            if (this.platform == 'desktop') {
                window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(`Acompanhe sua aposta clicando no link abaixo!! \n\n ${this.site}/ticket/${code} \n\n Apostador: ${apostador} \n Valor apostado: R$ ${this.formatCoin(valor)} \n Possível retorno: R$ ${this.formatCoin(premio)}`)}`,'_blank');
            } else {
                window.location.href = `whatsapp://send?text=${encodeURIComponent(`Acompanhe sua aposta clicando no link abaixo!! \n\n ${this.site}/ticket/${code} \n\n Apostador: ${apostador} \n Valor apostado: R$ ${this.formatCoin(valor)} \n Possível retorno: R$ ${this.formatCoin(premio)}`)}`;
            }
        },
    },
    watch: {
        limiteVendedores() {
            this.graphic();
        },
        apostasLimite() {
            this.graphic();
        },
        eventosLimite() {
            this.counter();
        },
        possiveisPremiacoes() {
            this.counter();
        },
        apostasDeRiscoLimite() {
            this.counter();
        },
        apostasDeRiscoOrder() {
            this.counter();
        },
        valoresAltosOrder() {
            this.counter();
        },
        'loads.principal'() {
            this.counter();
        },
        'loads.vendedores'() {
            this.counter();
        },
        'loads.apostas_altas'() {
            this.counter();
        },
        'loads.apostas_de_risco'() {
            this.counter();
        },
        'loads.possiveis_ganhadores'() {
            this.counter();
        },
        future_predictions_events() {
            setTimeout(() => {
                var button = document.querySelector('.carousel-control-next');

                if (button == null || button == undefined) {
                    return;
                }

                if (button.dispatchEvent) {
                    var e = document.createEvent("MouseEvents");
                    e.stopPropagation();
                    e.initEvent("click", true, true);
                    button.dispatchEvent(e);
                } else {
                    button.click();
                }
            }, 5000);
        }
    },
    computed: {
        limiteVendedores() {
            return this.limite.vendedores;
        },
        apostasLimite() {
            return this.limite.valores_altos;
        },
        eventosLimite() {
            return this.limite.eventos_mais_apostados;
        },
        apostasDeRiscoLimite() {
            return this.limite.apostas_de_risco;
        },
        possiveisPremiacoes() {
            return this.limite.possiveis_premiacoes;
        },
        valoresAltosOrder() {
            return this.info.apostas.valores_altos.slice().sort((a, b) => b.valor - a.valor).slice(0, this.limite.valores_altos);
        },
        apostasDeRiscoOrder() {
            return this.info.apostas.risco.slice().sort((a, b) => b.valor - a.valor).slice(0, this.limite.apostas_de_risco);
        },
        possiveisPremiacoesOrder() {
            return this.info.apostas.possiveis_premiacoes.slice().sort((a, b) => b.valor - a.valor).slice(0, this.limite.possiveis_premiacoes);
        }
    },
    beforeCreate() {
        history.pushState(null, null, document.location.origin);
    },
    beforeMount() {
        try {
            const {name, pipe} = JSON.parse(window.localStorage.getItem('auth'));
            this.pipe = pipe;
            this.user_name = name;
        } catch(e) {
            this.pipe = 0;
            this.user_name = 'Anônimo';
        }
    },
    mounted() {
        this.withdrawalRequests();
    },
    created() {

        const self = this;
        const pipe = self.pipe ? self.pipe : localStorage.getItem('firebase:pipe');
        const url = pipe == 2 ? `dashboard/manager?type=${self.isClientSrv}` : pipe == 3 ? `dashboard?type=${self.isClientSrv}` : `dashboard/supervisor?type=${self.isClientSrv}`;

        self.isLoading = true;
        self.paymentReminder(pipe);
         
        api.get(url).then((response) => {
            switch(response.data.result) {
                case 'success':
                    // self.futurePredictions();
                    self.mountAssemblesStatistics(response.data);
                break;
                default:
                    self.display = 2;
                    self.display_show = 2;
                    self.current_credit = parseFloat(response.data.credit);
                break;
            }
        }).catch((error) => {
            try {
                Swal.fire(
                    'Falha',
                    self.network_erros[error.status]['message'],
                    'error'
                );
            } catch(e) {
                Swal.fire(
                    'Falha',
                    self.network_erros[408]['message'],
                    'error'
                );
            }
        }).finally(() => {
            self.isLoading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.card.no-border {
    border: none!important;
}
.bg-secondary-2 {
    background-color: #7a848c!important;
}
.carousel[data-page=dashboard] .carousel-control-prev,
.carousel[data-page=dashboard] .carousel-control-next {
    width: auto!important;
}
.carousel[data-page=dashboard] .carousel-control-prev {
    left: -30px!important;
}
.carousel[data-page=dashboard] .carousel-control-next {
    right: -30px!important;
}
h5.border-bottom,
.row.border-bottom,
.col.border-bottom,
.row .col-12.border-bottom {
    border-color: rgb(222 226 230 / 5%)!important;
}
.col.border-right {
    border-color: rgb(222 226 230 / 5%)!important;
}
.row:not(:first-child) .col-header {
    background-color: rgb(222 226 230 / 5%)!important;
}
#pills-3 .row-scrolling.pl-3.pr-3.pb-3.pt-3 {
    border-top: 1px solid rgb(222 226 230 / 5%)!important;
}
.vh-100 {
    height: calc(90vh - 80px)!important;
}
.d-flex.vh-100.justify-content-center.align-items-center .w-50.text-center.p-3.rounded-pill,
.d-flex.vh-100.justify-content-center.align-items-center .w-100.text-center.p-3.rounded-pill {
    background-color: #313131;
}
.row-scrolling {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
}
.row-scrolling::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.row-scrolling::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.row-scrolling::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0;
}
.row-scrolling::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.row-scrolling::-webkit-scrollbar-thumb:active {
  background: #ddd;
}
.row-scrolling::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0;
}
.row-scrolling::-webkit-scrollbar-track:hover {
  background: #666666;
}
.row-scrolling::-webkit-scrollbar-track:active {
  background: #666666;
}
.row-scrolling::-webkit-scrollbar-corner {
  background: transparent;
}
.row ul.nav-pills.nav-justified.w-100.p-3 .nav-link.active {
    background-color: #607D8B;
}
.modal-view {
    width: 100%;
    height: calc(100% - 36.8px);
    overflow-y: auto;
    overflow-x: hidden;
    color: var(--theme);
    background-color: var(--gray-dark);
}
.modal-view::-webkit-scrollbar {
    width: 4px!important;
    height: 4px!important;
    background: var(--gray)!important;
    border-radius: 0;
}
.modal-view::-webkit-scrollbar-thumb {
    width: 4px!important;
    height: 4px!important;
    background: var(--gray-dark)!important;
}
.modal-view .border-bottom {
    border-bottom: 1px solid var(--foreground);
}
</style>